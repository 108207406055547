import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthListener from '../services/useAuthListener';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user, loading } = useAuthListener();

  if (loading) {
    // You can return a loading spinner or null if you want to wait until the user state is determined
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
