import React, { useState, useEffect } from 'react';
import { useTheme, useMediaQuery, SelectChangeEvent } from '@mui/material';
import {
  Box, Typography, TextField, FormControl, Select, MenuItem, Button, Grid, Avatar, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Contact, InviteModalProps } from './types'; // Import interfaces from types file
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../services/firebase';

const InviteModal: React.FC<InviteModalProps> = ({ open, onClose, onInvite }) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [uniqueGroups, setUniqueGroups] = useState<string[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsPerSlide = isMobile ? 4 : 5;

  useEffect(() => {
    const fetchContactsFromFirestore = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const userContacts = userData.contacts || [];
          setContacts(userContacts);
          setFilteredContacts(userContacts);
          const groups = Array.from(new Set(userContacts.flatMap((contact: Contact) => contact.contactGroup))) as string[];
          setUniqueGroups(groups);
        } else {
          console.error('No such document!');
        }
      } else {
        console.error('User not authenticated');
      }
    };

    fetchContactsFromFirestore();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    filterContacts(term, selectedGroup);
  };

  const handleGroupFilter = (event: SelectChangeEvent<string>) => {
    const group = event.target.value;
    setSelectedGroup(group);
    filterContacts(searchTerm, group);
  };

  const filterContacts = (term: string, group: string) => {
    const filtered = contacts.filter(
      (contact) =>
        (contact.firstName.toLowerCase().includes(term) || contact.lastName.toLowerCase().includes(term)) &&
        (group === '' || contact.contactGroup.includes(group))
    );
    setFilteredContacts(filtered);
  };

  const handleContactClick = (contact: Contact) => {
    if (selectedContacts.some((c) => c.userId === contact.userId)) {
      setSelectedContacts(selectedContacts.filter((c) => c.userId !== contact.userId));
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
  };

  const groupContacts = (contacts: Contact[], itemsPerSlide: number) => {
    const groupedContacts = [];
    for (let i = 0; i < contacts.length; i += itemsPerSlide) {
      groupedContacts.push(contacts.slice(i, i + itemsPerSlide));
    }
    return groupedContacts;
  };

  const handleInviteClick = () => {
    onInvite(selectedContacts);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Invite Contacts</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearch}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select value={selectedGroup} onChange={handleGroupFilter} displayEmpty>
                  <MenuItem value="">All Groups</MenuItem>
                  {uniqueGroups.map((group) => (
                    <MenuItem key={group} value={group}>
                      {group}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Carousel
            navButtonsAlwaysVisible={!isMobile}
            animation="slide"
            swipe
            indicators={false}
            autoPlay={false}
          >
            {groupContacts(filteredContacts, itemsPerSlide).map((contactGroup, index) => (
              <Grid container spacing={2} justifyContent="center" alignItems="center" key={index}>
                {contactGroup.map((contact) => (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={contact.userId}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      onClick={() => handleContactClick(contact)}
                      sx={{
                        cursor: 'pointer',
                        border: selectedContacts.some((c) => c.userId === contact.userId) ? '2px solid #1876D3' : 'none',
                        borderRadius: '8px',
                        padding: '6px',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    >
                      <Avatar
                        src={contact.picUrl}
                        alt={`${contact.firstName} ${contact.lastName}`}
                        sx={{ width: 40, height: 40 }}
                      />
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: 1, textAlign: 'center' }}>
                        {contact.firstName} {contact.lastName}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Carousel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleInviteClick} color="primary" variant="contained">
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteModal;
