import { signOut } from 'firebase/auth';
import { auth } from '../services/firebase';

export const handleLogout = async (navigate: Function) => {
  try {
    await signOut(auth);
    navigate('/login');  // Adjust the path as needed
  } catch (error) {
    console.error('Logout failed:', error);
  }
};
