import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Grid } from '@mui/material';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth } from '../services/firebase';
import test_contacts from '../services/test_contacts.json'; // Import the simulated contacts

const db = getFirestore();
const storage = getStorage();

const Registration = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [picture, setPicture] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    if (password.length < 6) {
      setPasswordError('Password should be at least 6 characters');
    } else {
      setPasswordError(null);
    }
  };

  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (passwordError) {
      return;
    }

    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        setError('This email is already registered. Please use a different email.');
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      let pictureUrl = '';
      if (picture) {
        const fileExtension = picture.name.split('.').pop();
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileSizeLimit = 5 * 1024 * 1024; // 5 MB limit

        if (!allowedExtensions.includes(fileExtension?.toLowerCase() || '')) {
          setError('Invalid file type. Please upload a JPG or PNG image.');
          return;
        }

        if (picture.size > fileSizeLimit) {
          setError('File is too large. Please upload an image smaller than 5 MB.');
          return;
        }

        const pictureRef = ref(storage, `users/${user.uid}/profilePicture`);
        await uploadBytes(pictureRef, picture);
        pictureUrl = await getDownloadURL(pictureRef);
      }

      // Add user data and simulated contacts to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        firstName,
        lastName,
        email,
        phone,
        pictureUrl,
        contacts: test_contacts, // Add the simulated contacts here
      });

      navigate('/home'); // Redirect to home page after successful registration
    } catch (error: any) {
      console.error("Registration error:", error);
      if (error.code === 'auth/email-already-in-use') {
        setError('The email address is already in use by another account.');
      } else {
        setError("Failed to register. Please try again.");
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="90vh">
      <Typography variant="h4" gutterBottom>Register</Typography>
      {error && <Typography color="error" gutterBottom>{error}</Typography>}
      <form onSubmit={handleRegister} style={{ width: '60%' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              margin="normal"
              required
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              margin="normal"
              required
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <TextField
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          margin="normal"
          required
          sx={{ width: '100%' }}
        />
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          required
          autoComplete="email"
          sx={{ width: '100%' }}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            validatePassword(e.target.value);
          }}
          fullWidth
          margin="normal"
          required
          autoComplete="current-password"
          error={!!passwordError}
          helperText={passwordError}
          sx={{ width: '100%' }}
        />
        <TextField
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
          autoComplete="new-password"
          sx={{ width: '100%' }}
        />
        <Button variant="contained" component="label" sx={{ width: '100%' }}>
          Upload Picture (Optional)
          <input type="file" hidden onChange={(e) => setPicture(e.target.files ? e.target.files[0] : null)} />
        </Button>
        <Button variant="contained" color="primary" type="submit" fullWidth sx={{ width: '100%', mt: 2 }}>
          Register
        </Button>
      </form>
      <Button
        variant="text"
        color="secondary"
        onClick={() => navigate('/login')}
        style={{ marginTop: '1rem' }}
      >
        Already have an account? Login
      </Button>
    </Box>
  );
};

export default Registration;

