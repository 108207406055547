import React from 'react';
import { Box, Button, Typography, Avatar } from '@mui/material';

const FDate = () => {
  const groupMembers = [
    { name: 'John Doe', foodSelected: true, moodSelected: true },
    { name: 'Jane Doe', foodSelected: false, moodSelected: true },
  ];

  return (
    <div>
      <Box display="flex" justifyContent="center" mb={2}>
        <h1>Foodiate - PLACEHOLDER </h1>
      </Box>

      <Box>
        <Typography variant="h4" mb={4}>Top Restaurant Suggestions</Typography>
        {/* Display restaurant suggestions here */}
        <Typography variant="h6" mt={4}>Group Name</Typography>

        <Box display="flex" justifyContent="space-around">
          {groupMembers.map((member, index) => (
            <Box key={index} textAlign="center">
              <Avatar style={{ backgroundColor: member.foodSelected && member.moodSelected ? 'green' : 'orange' }}>
                {member.name[0]}
              </Avatar>
              <Typography>{member.name}</Typography>
              <Button variant="contained">Message</Button>
            </Box>
          ))}
        </Box>
      </Box>

    </div>
  );
};

export default FDate;
