import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemText, Chip, Button } from '@mui/material';
import { doc, getDoc, updateDoc, collection, getDocs, QueryDocumentSnapshot } from 'firebase/firestore';
import { db, auth } from '../services/firebase';
import InviteModal from './InviteModal';
import { format } from 'date-fns';
import { Contact, EventDetails } from './types';

const Events = () => {
  const [events, setEvents] = useState<EventDetails[]>([]);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [currentEventId, setCurrentEventId] = useState<string | null>(null);

  // const fetchEvents = async () => {
  //   try {
  //     const user = auth.currentUser;
  //     if (!user) {
  //       console.error('User not authenticated');
  //       return;
  //     }

  //     const querySnapshot = await getDocs(collection(db, 'events'));
  //     const eventsData: EventDetails[] = querySnapshot.docs
  //       .map((doc) => ({ id: doc.id, ...doc.data() } as EventDetails))
  //       .filter(
  //         (event) =>
  //           event.ownerId === user.uid ||
  //           event.invitedContacts.some((contact) => contact.userId === user.uid)
  //       );

  //     setEvents(eventsData);
  //   } catch (error) {
  //     console.error('Failed to fetch events:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchEvents();
  // }, []);

  const fetchEvents = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      const querySnapshot = await getDocs(collection(db, 'events'));
      const eventsData: EventDetails[] = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() } as EventDetails))
        .filter(
          (event) =>
            event.ownerId === user.uid ||
            event.invitedContacts.some((contact) => contact.userId === user.uid)
        );

      setEvents(eventsData);
    } catch (error) {
      console.error('Failed to fetch events:', error);
    }
  };

  useEffect(() => {
    // Function to fetch events initially and at regular intervals
    const fetchEventsData = async () => {
      await fetchEvents();
      console.log("Events - refreshed...");
    };
  
    // Initial fetch when the component mounts
    fetchEventsData();
  
    // Set up interval to fetch events every 30 seconds
    const intervalId = setInterval(fetchEventsData, 30000); // 30000ms = 30 seconds
  
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  const formatDateTime = (dateString: string | null, timeString: string | null) => {
    if (!dateString || !timeString) return 'N/A';
    const date = new Date(dateString);
    return `${format(date, 'MMMM do')} at ${format(new Date(`1970-01-01T${timeString}:00`), 'h:mm a')}`;
  };

  const handleInvite = async (eventId: string) => {
    // console.log('Events - handleInvite - eventId: ', eventId);
  
    try {
      // Step 1: Query Firestore for the document containing the eventId
      const eventsQuerySnapshot = await getDocs(collection(db, 'events'));
    
      let targetEventDoc: QueryDocumentSnapshot<EventDetails> | undefined;
    
      eventsQuerySnapshot.forEach((doc) => {
        const eventData = doc.data() as EventDetails;
        if (eventData.id === eventId) {
          targetEventDoc = doc as QueryDocumentSnapshot<EventDetails>;
        }
      });
    
      if (!targetEventDoc) {
        console.error('Event not found, eventId: ', eventId);
        return;
      }
    
      // Step 2: Set the current event ID and open the invite modal
      setCurrentEventId(targetEventDoc.id);
      setInviteModalOpen(true);
    
      // console.log('Invite modal opened for event ID: ', targetEventDoc.id);
    } catch (error) {
      console.error('Failed to fetch event for invite: ', error);
    }
  };
  
  const handleCancel = async (eventId: string) => {
    // console.log('Events - handleCancel - eventId: ',eventId);
    try {
      // Query Firestore for the document containing the eventId field matching the provided eventId
      const eventsQuerySnapshot = await getDocs(collection(db, 'events'));
      let targetEventDoc: QueryDocumentSnapshot<EventDetails> | undefined;
  
      eventsQuerySnapshot.forEach((doc) => {
        const eventData = doc.data() as EventDetails;
        if (eventData.id === eventId) {
          targetEventDoc = doc as QueryDocumentSnapshot<EventDetails>;
        }
      });
  
      if (!targetEventDoc) {
        console.error('Event not found');
        return;
      }
  
      const eventData = targetEventDoc.data();
      const newStatus = eventData.eventStatus === 'scheduled' ? 'cancelled' : 'scheduled';
  
      await updateDoc(targetEventDoc.ref, { eventStatus: newStatus });
  
      console.log(`Event status updated to ${newStatus}`);
      fetchEvents(); // Re-fetch events to update the UI
    } catch (error) {
      console.error('Failed to update event status:', error);
    }
  };
  
  const handleEventResponse = async (eventId: string, userId: string, status: 'accepted' | 'not_accepted') => {
    // console.log('Events - handleEventResponse - eventId: ',eventId);
    // console.log('Events - handleEventResponse - userId: ',userId);
    // console.log('Events - handleEventResponse - status: ',status);
    try {
      // Step 1: Query Firestore for the document containing the eventId
      const eventsQuerySnapshot = await getDocs(collection(db, 'events'));
  
      let targetEventDoc: QueryDocumentSnapshot<EventDetails> | undefined;
  
      eventsQuerySnapshot.forEach((doc) => {
        const eventData = doc.data() as EventDetails;
        if (eventData.id === eventId) {
          targetEventDoc = doc as QueryDocumentSnapshot<EventDetails>;
        }
      });
  
      if (!targetEventDoc) {
        console.error('Event not found, eventId: ', eventId);
        return;
      }
  
      // Step 2: Update the specific contact's eventInviteStatus
      const eventData = targetEventDoc.data();
      const updatedInvitedContacts = eventData.invitedContacts.map((contact) =>
        contact.userId === userId ? { ...contact, eventInviteStatus: status } : contact
      );
  
      await updateDoc(targetEventDoc.ref, {
        invitedContacts: updatedInvitedContacts,
      });
  
      // console.log(`User ${userId}'s status updated to ${status} for event ID: ${eventId}`);
      fetchEvents(); // Re-fetch events to update the UI
    } catch (error) {
      console.error('Failed to update user status:', error);
    }
  };
  
  const handleInviteContacts = async (selectedContacts: Contact[]) => {
    if (!currentEventId) {
      console.error('No event ID is selected.');
      return;
    }

    try {
      const eventDocRef = doc(db, 'events', currentEventId);
      const eventDoc = await getDoc(eventDocRef);

      if (!eventDoc.exists()) {
        console.error('Event not found');
        return;
      }

      const eventData = eventDoc.data() as EventDetails;
      const updatedInvitedContacts = [
        ...eventData.invitedContacts,
        ...selectedContacts.map(contact => ({
          ...contact,
          eventInviteStatus: 'not_accepted',
        }))
      ];

      await updateDoc(eventDocRef, {
        invitedContacts: updatedInvitedContacts,
      });

      console.log('Event updated successfully');
      setInviteModalOpen(false);

      fetchEvents(); // Re-fetch the events to reflect the update in the UI
    } catch (error) {
      console.error('Failed to update event:', error);
    }
  };

  const handleContactClick = async (eventId: string, contactId: string) => {
    try {
      // Step 1: Query Firestore for the document containing the eventId field matching the provided eventId
      const eventsQuerySnapshot = await getDocs(collection(db, 'events'));
      let targetEventDoc: QueryDocumentSnapshot<EventDetails> | undefined;
  
      eventsQuerySnapshot.forEach((doc) => {
        const eventData = doc.data() as EventDetails;
        if (eventData.id === eventId) {
          targetEventDoc = doc as QueryDocumentSnapshot<EventDetails>;
        }
      });
  
      if (!targetEventDoc) {
        console.error('Event not found');
        return;
      }
  
      // Step 2: Update the specific contact's eventInviteStatus
      const eventData = targetEventDoc.data();
      const updatedInvitedContacts = eventData.invitedContacts.map((contact) =>
        contact.userId === contactId
          ? { ...contact, eventInviteStatus: contact.eventInviteStatus === 'uninvited' ? 'not_accepted' : 'uninvited' }
          : contact
      );
  
      await updateDoc(targetEventDoc.ref, {
        invitedContacts: updatedInvitedContacts,
      });
  
      console.log('Contact status updated successfully');
      fetchEvents(); // Re-fetch events to update the UI
    } catch (error) {
      console.error('Failed to update contact status:', error);
    }
  };
  

  return (
    <Box sx={{ width: '90%', margin: '0 auto', paddingTop: '20px' }}>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h4">Events</Typography>
      </Box>

      {events.length > 0 ? (
        events.map((event, index) => {
          const isOwner = event.ownerId === auth.currentUser?.uid;
          const isCancelled = event.eventStatus === 'cancelled';

          return (
            <Box key={event.id} mb={2} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    backgroundColor: isCancelled ? 'orange' : 'transparent',
                    borderRadius: isCancelled ? '8px' : '0',
                    padding: isCancelled ? '4px 8px' : '0',
                  }}
                >
                  {event.eventName} {isOwner && '(event owner)'}
                </Typography>
                <Box>
                  {isOwner ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ marginRight: '8px' }}
                        onClick={() => handleInvite(event.id)}
                      >
                        Invite
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleCancel(event.id)}
                      >
                        {isCancelled ? 'Remove CANCEL' : 'Cancel'}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{ marginRight: '8px' }}
                        onClick={() => handleEventResponse(event.id, auth.currentUser?.uid || '', 'accepted')}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleEventResponse(event.id, auth.currentUser?.uid || '', 'not_accepted')}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
              <List dense>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Box component="span" sx={{ textDecoration: 'underline' }}>
                        Date & Time
                      </Box>
                    }
                    secondary={formatDateTime(event.eventDate, event.eventTime)}
                  />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Invited (status ={' '}
                        <Typography sx={{ marginLeft: '10px' }}>
                          <span style={{ color: 'red' }}>not accepted</span>,{' '}
                          <span style={{ color: 'green' }}>accepted</span>,{' '}
                          <span style={{ color: 'orange' }}>uninvited</span>
                        </Typography>
                        {' '})
                      </Box>
                    }
                    sx={{ marginRight: '50px', borderRadius: '8px' }}
                  />
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {event.invitedContacts.map((contact, idx) => (
                      <Chip
                        key={idx}
                        label={`${contact.firstName} ${contact.lastName}`}
                        onClick={isOwner ? () => handleContactClick(event.id, contact.userId) : undefined}
                        sx={{
                          backgroundColor: contact.eventInviteStatus === 'accepted' ? 'green' : contact.eventInviteStatus === 'uninvited' ? 'orange' : 'red',
                          color: 'white',
                          borderRadius: '16px',
                          cursor: isOwner ? 'pointer' : 'default',
                        }}
                      />
                    ))}
                  </Box>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText primary="Food Selection" secondary={event.selectedFood.map((food) => food.type).join(', ') || 'N/A'} />
                </ListItem>
              </List>
              {index < events.length - 1 && <Divider sx={{ margin: '10px 0' }} />}
            </Box>
          );
        })
      ) : (
        <Typography>No event details available.</Typography>
      )}

      <InviteModal
        open={isInviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInvite={handleInviteContacts}
      />
    </Box>
  );
};

export default Events;

