import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Button, Grid, Box, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { db } from '../services/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const libraries: ('places')[] = ['places'];

const containerStyle = {
  width: '80%',
  height: '80vh',
  margin: '0 auto', // Center the map horizontally
};

const center = {
  lat: 0,
  lng: 0,
};

const Find: React.FC = () => {
  const [mapCenter, setMapCenter] = useState(center);
  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | null>(null);
  const [places, setPlaces] = useState<any[]>([]);
  const [type, setType] = useState<string>('restaurant'); // Default to 'restaurant'
  const [categories, setCategories] = useState<string[][]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>('American'); // Default to 'American'
  const [carouselIndex, setCarouselIndex] = useState<number>(0); // Track the index of the selected group
  const mapRef = useRef<google.maps.Map | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null);
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', // Ensure a string is passed
    libraries, // Use mutable type
  });

  const loadSelectedType = () => {
    const savedType = localStorage.getItem('selectedType');
    if (savedType) {
      setType(savedType);
    }
  };

  const handleSuccess = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    const userLocation = { lat: latitude, lng: longitude };

    if (mapRef.current) {
      mapRef.current.panTo(userLocation);
      mapRef.current.setZoom(14); // Adjust the zoom level to your preference
    }
    setUserLocation(userLocation);
    setMapCenter(userLocation);
  };

  const handleError = (error: GeolocationPositionError) => {
    console.error('Geolocation failed or was blocked:', error.message);
    setMapCenter(center); // Fallback to default center if geolocation fails
  };

  const attemptGeolocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
        enableHighAccuracy: true,
        timeout: 20000, // Increase timeout to 20 seconds
        maximumAge: 0,
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  useEffect(() => {
    loadSelectedType();
    attemptGeolocation();
  }, [attemptGeolocation, isLoaded]);

  const fetchPlaces = useCallback(() => {
    if (userLocation && type && placesServiceRef.current) {
      const request = {
        location: userLocation,
        radius: 1609, // 1 mile in meters
        type,
      };

      placesServiceRef.current.nearbySearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          setPlaces(results);
        } else {
          console.error('Error fetching places:', status);
        }
      });
    }
  }, [userLocation, type]);

  useEffect(() => {
    if (type) {
      fetchPlaces();
    }
  }, [type, fetchPlaces]);

  useEffect(() => {
    async function fetchFoodCategories() {
      try {
        const querySnapshot = await getDocs(collection(db, 'food_category'));

        const uniqueCategoriesSet = new Set<string>();
        querySnapshot.forEach(doc => {
          uniqueCategoriesSet.add(doc.data().food_category);
        });

        let categoriesData = Array.from(uniqueCategoriesSet).sort((a, b) => a.localeCompare(b));

        const groupedCategories: string[][] = [];
        for (let i = 0; i < categoriesData.length; i += 3) {
          groupedCategories.push(categoriesData.slice(i, i + 3));
        }

        setCategories(groupedCategories);

        // Set default to 'American' if it exists
        const americanIndex = categoriesData.indexOf('American');
        if (americanIndex !== -1) {
          setSelectedCategory('American');
          setCarouselIndex(Math.floor(americanIndex / 3));
        }

      } catch (error) {
        console.error("Error fetching food categories: ", error);
      }
    }

    fetchFoodCategories();
  }, []);

  const FoodCarousel = ({ categories, onSelect }: { categories: string[][]; onSelect: (category: string, groupIndex: number) => void; }) => {
    return (
      <Box
        sx={{
          width: '80%',
          margin: '0 auto',
          marginBottom: '20px',
        }}
      >
        <Carousel
          navButtonsAlwaysVisible={true}
          animation="slide"
          swipe={true}
          indicators={false}
          fullHeightHover={false}
          autoPlay={false}
          height="100px"
          index={carouselIndex}
        >
          {categories.map((group, groupIndex) => (
            <Box
              key={groupIndex}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100px"
            >
              {group.map((category, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="33%"
                  onClick={() => onSelect(category, groupIndex)}
                  sx={{
                    cursor: 'pointer',
                    opacity: selectedCategory === category ? 1 : 0.7,
                    transform: selectedCategory === category ? 'scale(1.2)' : 'scale(1)',
                    color: selectedCategory === category ? 'red' : 'inherit',
                    transition: 'transform 0.5s, opacity 0.5s, color 0.5s',
                  }}
                >
                  <Typography variant="h6">{category}</Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Carousel>
      </Box>
    );
  };

  const handleCategorySelect = (category: string, groupIndex: number) => {
    setSelectedCategory(category);
    setType(category.toLowerCase());
    setCarouselIndex(groupIndex);
  };

  const handleTypeSelect = (selectedType: string) => {
    setType(selectedType);
    localStorage.setItem('selectedType', selectedType); // Save the selection to local storage
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Box display="flex" justifyContent="center" mb={2}>
        <h1>Find</h1>
      </Box>

      <div style={{ textAlign: 'center', margin: '5px 0' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color={type === 'bar' ? 'error' : 'primary'}
              onClick={() => handleTypeSelect('bar')}
            >
              Bars
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={type === 'restaurant' ? 'error' : 'primary'}
              onClick={() => handleTypeSelect('restaurant')}
            >
              Restaurants
            </Button>
          </Grid>
        </Grid>
      </div>

      <FoodCarousel
        categories={categories}
        onSelect={handleCategorySelect}
      />

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={14}
        onLoad={(map) => {
          mapRef.current = map;
          placesServiceRef.current = new google.maps.places.PlacesService(map);
        }}
      >
        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#00B0F3",
              fillOpacity: 0.8,
              strokeColor: "blue",
              strokeWeight: 2,
              scale: 14,
              labelOrigin: new google.maps.Point(0, 0),
            }}
            label={{
              text: "You",
              color: "#FF0000",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          />
        )}
        {places.map((place) => (
          <Marker key={place.place_id} position={place.geometry.location} label={place.name} />
        ))}
      </GoogleMap>

      {/* Add the fDate button */}
      <Box textAlign="center" mt={4}>
        <Box
          onClick={() => navigate('/fdate')}
          sx={{
            width: 80,
            height: 80,
            borderRadius: '50%',
            backgroundColor: 'orange',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            margin: '0 auto',
          }}
        >
          <Typography variant="h6" color="white">
            fDate
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Find;




