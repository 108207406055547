import { useState, useEffect } from 'react';
import { auth } from './firebase'; // Ensure the correct path to your firebase config file
import { onAuthStateChanged } from 'firebase/auth';

const useAuthListener = () => {
  const [user, setUser] = useState<null | object>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe(); 
  }, []);

  // console.log("useAuthListener - useEffect - user: ",user);
  return { user, loading };
};

export default useAuthListener;
