import React, { createContext, useState, useContext, ReactNode } from 'react';
import { EventDetails } from './types';

interface EventContextType {
  eventDetails: EventDetails[];
  saveEventDetails: (details: EventDetails) => void;
}

const EventContext = createContext<EventContextType | undefined>(undefined);

export const useEvent = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error('useEvent must be used within an EventProvider');
  }
  return context;
};

export const EventProvider = ({ children }: { children: ReactNode }) => {
  const [eventDetails, setEventDetails] = useState<EventDetails[]>([]);

  const saveEventDetails = (details: EventDetails) => {
    setEventDetails((prevDetails) => [...prevDetails, details]);
  };

  return (
    <EventContext.Provider value={{ eventDetails, saveEventDetails }}>
      {children}
    </EventContext.Provider>
  );
};

