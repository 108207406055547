import React, { useState, useEffect } from 'react';
import {
  Grid, Box, Typography, Avatar, useMediaQuery, useTheme,
  TextField, FormControl, Select, MenuItem, Button, SelectChangeEvent
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../services/firebase';
import { Contact, FoodType, EventDetails } from './types'; // Import interfaces from types file

interface FoodCategory {
  food_category: string;
  food_type: FoodType[];
}

const Home: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [uniqueGroups, setUniqueGroups] = useState<string[]>([]);
  const [eventDate, setEventDate] = useState<Date | null>(new Date());
  const [eventTime, setEventTime] = useState<string | null>('10:00');
  const [foodCategories, setFoodCategories] = useState<FoodCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<FoodCategory | null>(null);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [selectedFoodTypes, setSelectedFoodTypes] = useState<FoodType[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsPerSlide = isMobile ? 4 : 5;
  const itemsPerSlideFoodTypes = isMobile ? 3 : 4;
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const groupedFoodCategories = Array.from({ length: Math.ceil(foodCategories.length / itemsPerSlideFoodTypes) }, (_, index) =>
    foodCategories.slice(index * itemsPerSlideFoodTypes, index * itemsPerSlideFoodTypes + itemsPerSlideFoodTypes)
  );

  useEffect(() => {
    const fetchContactsFromFirestore = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const userContacts = userData.contacts || [];
          setContacts(userContacts);
          setFilteredContacts(userContacts);
          const groups = Array.from(new Set(userContacts.flatMap((contact: Contact) => contact.contactGroup))) as string[];
          setUniqueGroups(groups);
        } else {
          console.error('No such document!');
        }
      } else {
        console.error('User not authenticated');
      }
    };

    const fetchFoodCategories = async () => {
      try {
        const response = await fetch('/foodMaster.json');
        const data: FoodCategory[] = await response.json();
        setFoodCategories(data);
      } catch (error) {
        console.error('Error loading food categories:', error);
      }
    };

    fetchContactsFromFirestore();
    fetchFoodCategories();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    filterContacts(term, selectedGroup);
  };

  const handleGroupFilter = (event: SelectChangeEvent<string>) => {
    const group = event.target.value;
    setSelectedGroup(group);
    filterContacts(searchTerm, group);
  };

  const filterContacts = (term: string, group: string) => {
    const filtered = contacts.filter(
      (contact) =>
        (contact.firstName.toLowerCase().includes(term) || contact.lastName.toLowerCase().includes(term)) &&
        (group === '' || contact.contactGroup.includes(group))
    );
    setFilteredContacts(filtered);
  };

  const groupContacts = (contacts: Contact[], itemsPerSlide: number) => {
    const groupedContacts = [];
    for (let i = 0; i < contacts.length; i += itemsPerSlide) {
      groupedContacts.push(contacts.slice(i, i + itemsPerSlide));
    }
    return groupedContacts;
  };

  const handleSelectCategory = (category: FoodCategory, index: number) => {
    setSelectedCategory(category);
    setSelectedCategoryIndex(index);
  };

  const handleContactClick = (contact: Contact) => {
    if (selectedContacts.some((c) => c.userId === contact.userId)) {
      setSelectedContacts(selectedContacts.filter((c) => c.userId !== contact.userId));
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
  };

  const handleFoodTypeClick = (foodType: FoodType) => {
    if (selectedFoodTypes.some((ft) => ft.type === foodType.type)) {
      setSelectedFoodTypes(selectedFoodTypes.filter((ft) => ft.type !== foodType.type));
    } else if (selectedFoodTypes.length < 3) {
      setSelectedFoodTypes([...selectedFoodTypes, foodType]);
    } else {
      alert('You can only select up to 3 food types. Please remove one to add a new selection.');
    }
  };

  const moveFoodType = (index: number, direction: 'up' | 'down') => {
    const newSelectedFoodTypes = [...selectedFoodTypes];
    if (direction === 'up' && index > 0) {
      [newSelectedFoodTypes[index], newSelectedFoodTypes[index - 1]] = [newSelectedFoodTypes[index - 1], newSelectedFoodTypes[index]];
    } else if (direction === 'down' && index < selectedFoodTypes.length - 1) {
      [newSelectedFoodTypes[index], newSelectedFoodTypes[index + 1]] = [newSelectedFoodTypes[index + 1], newSelectedFoodTypes[index]];
    }
    setSelectedFoodTypes(newSelectedFoodTypes);
  };

  const handleCreateEvent = async () => {
    const eventName = prompt('Enter Event Name:');
    if (!eventName) {
      alert('Event name is required!');
      return;
    }
  
    const user = auth.currentUser; // Get the logged-in user
    if (!user) {
      alert('User not authenticated');
      return;
    }
  
    const eventDetails: EventDetails = {
      id: crypto.randomUUID(), 
      eventName,
      eventDate: eventDate ? eventDate.toISOString() : null,
      eventTime,
      eventStatus: 'scheduled',
      invitedContacts: selectedContacts.map((contact) => ({
        ...contact,
        eventInviteStatus: 'not_accepted',
      })),
      selectedFood: selectedFoodTypes.map((food) => ({
        ...food,
      })),
      ownerId: user.uid, 
    };
  
    try {
      await addDoc(collection(db, 'events'), eventDetails);
      alert('Event created and saved!');
      navigate('/events');
    } catch (error) {
      console.error('Error saving event:', error);
      alert('Failed to create event. Please try again.');
    }
  };
  

  const getFoodImagePath = (foodType: string): string => {
    const formattedType = foodType.replace(/ /g, '_');
    return `/Food_Data/Food_Pics/${formattedType}.jpg`;
  };

  return (
    <Box p={2} fontFamily="'Roboto', sans-serif">
      {/* Event Section */}
      <Box mb={3}>
        <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
          <Grid item xs={12} md={4} textAlign="center">
            <Typography variant="h4" fontWeight="bold">
              Event
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} textAlign="center">
            <DatePicker
              selected={eventDate}
              onChange={(date: Date | null) => setEventDate(date)}
              customInput={
                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      textAlign: 'center',
                      padding: '8px',
                      fontSize: '16px',
                    },
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={4} textAlign="center">
            <TimePicker
              onChange={(newTime) => setEventTime(newTime)}
              value={eventTime}
              clockIcon={null}
              clearIcon={null}
              disableClock={true}
              className="custom-time-picker"
            />
          </Grid>
        </Grid>
      </Box>

      {/* Contacts Section */}
      <Box mb={5}>
        <Grid container direction="column" spacing={4} style={{ width: '100%', margin: '0 auto' }}>
          <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
            <Grid item xs={12} md={4} textAlign="center">
              <Typography variant="h4" fontWeight="bold">
                Contacts
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} textAlign="center">
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearch}
                sx={{ marginRight: 2, width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={4} textAlign="center">
              <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
                <Select value={selectedGroup} onChange={handleGroupFilter} displayEmpty>
                  <MenuItem value="">All Groups</MenuItem>
                  {uniqueGroups.map((group) => (
                    <MenuItem key={group} value={group}>
                      {group}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Contacts Carousel */}
          <Grid item style={{ width: '90%', margin: '0 auto' }}>
            <Carousel
              navButtonsAlwaysVisible={!isMobile}
              animation="slide"
              swipe={true}
              indicators={false}
              autoPlay={false}
              sx={{
                '& .MuiButtonBase-root': {
                  width: isMobile ? '0px' : '20px', // Make arrows larger on desktop
                  height: isMobile ? '0px' : '20px', // Hide arrows on mobile
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#1876D3', 
                  color: 'white',
                  borderRadius: '50%', // Circular arrows
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', // Add shadow for better visibility
                  top: '50%', // Center vertically
                  transform: 'translateY(-50%)', // Center vertically
                },
                '& .MuiButtonBase-root:first-of-type': {
                  marginRight: isMobile ? '0px' : '5px',
                },
                '& .MuiButtonBase-root:last-of-type': {
                  marginLeft: isMobile ? '0px' : '5px',
                },
              }}
            >
              {groupContacts(filteredContacts, itemsPerSlide).map((contactGroup, index) => (
                <Grid container spacing={2} justifyContent="center" alignItems="center" key={index}>
                  {contactGroup.map((contact) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={contact.userId}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        onClick={() => handleContactClick(contact)}
                        sx={{
                          cursor: 'pointer',
                          border: selectedContacts.some((c) => c.userId === contact.userId) ? '2px solid #1876D3' : 'none',
                          borderRadius: '8px',
                          padding: '6px',
                          transition: 'transform 0.3s ease',
                          '&:hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                      >
                        <Avatar
                          src={contact.picUrl}
                          alt={`${contact.firstName} ${contact.lastName}`}
                          sx={{ width: 40, height: 40 }}
                        />
                        <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: 1, textAlign: 'center' }}>
                          {contact.firstName} {contact.lastName}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>

      {/* Food Types Section */}
      <div style={{ marginBottom: '40px' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>
              Food Types
            </Typography>
          </Grid>
          <Grid item style={{ width: '80%', margin: '0 auto' }}>
            <Carousel
              navButtonsAlwaysVisible={!isMobile}
              animation="slide"
              swipe={true}
              indicators={false}
              autoPlay={false}
              sx={{
                '& .MuiButtonBase-root': {
                  width: isMobile ? '0px' : '20px', // Make arrows larger on desktop
                  height: isMobile ? '0px' : '20px', // Hide arrows on mobile
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#1876D3',
                  color: 'white',
                  borderRadius: '50%', // Circular arrows
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', // Add shadow for better visibility
                  top: '50%', // Center vertically
                  marginTop: '5px', // Add margin to push arrows down
                },
                '& .MuiButtonBase-root:first-of-type': {
                  marginRight: isMobile ? '0px' : '5px',
                  left: '0px', // Adjust the positioning as needed
                },
                '& .MuiButtonBase-root:last-of-type': {
                  marginLeft: isMobile ? '0px' : '5px',
                  right: '-20px', // Adjust the positioning as needed
                },
              }}
            >
              {groupedFoodCategories.map((categoryGroup, slideIndex) => (
                <Grid container spacing={2} justifyContent="center" key={slideIndex}>
                  {categoryGroup.map((category, index) => (
                    <Grid
                      item
                      xs={12} sm={6} md={4} lg={3}
                      key={index}
                      onClick={() => handleSelectCategory(category, index)}
                    >
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography
                          variant="h5"
                          align="center"
                          sx={{
                            fontWeight: 'bold',
                            color: selectedCategoryIndex === index ? '#1876D3' : 'inherit',
                            fontSize: selectedCategoryIndex === index ? '1.2em' : '1em',
                            transition: 'color 0.3s ease, font-size 0.3s ease',
                          }}
                        >
                          {category.food_category}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Carousel>
          </Grid>

          {selectedCategory && (
            <Grid item xs={12} style={{ width: '80%', marginTop: '10px' }}>
              <Grid container spacing={2} justifyContent="center">
                {selectedCategory.food_type.map((food, index) => (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      onClick={() => handleFoodTypeClick(food)}
                      sx={{ cursor: 'pointer', border: selectedFoodTypes.some(ft => ft.type === food.type) ? '2px solid #1876D3' : 'none' }}
                    >
                      {/* <img src={food.food_pic} alt={food.type} style={{ width: '25%', height: 'auto' }} /> */}
                      <img src={getFoodImagePath(food.type)} alt={food.type} style={{ width: '25%', height: 'auto' }} />
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: 0.75 }}>
                        {food.type}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>

      {/* Selections Section */}
      <Box mb={5}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} style={{ width: '80%' }}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ minHeight: '200px', padding: '20px', bgcolor: '#fafafa', borderRadius: '8px' }}>
                  <Typography align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Invited
                  </Typography>
                  <Grid container spacing={2}>
                    {selectedContacts.map((contact, index) => (
                      <Grid item xs={4} key={contact.userId}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          sx={{
                            cursor: 'pointer',
                            padding: '8px',
                            borderRadius: '8px',
                            border: '1px solid #e0e0e0',
                            '&:hover': { bgcolor: '#f0f0f0' },
                          }}
                          onClick={() => handleContactClick(contact)}
                        >
                          <Avatar src={contact.picUrl} sx={{ width: 40, height: 40 }} />
                          <Typography sx={{ fontSize: '0.75rem', textAlign: 'center', marginTop: '5px' }}>
                            {contact.firstName}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ minHeight: '200px', padding: '20px', bgcolor: '#fafafa', borderRadius: '8px' }}>
                  <Typography align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Food (select top 3)
                  </Typography>
                  <Grid container spacing={2}>
                    {selectedFoodTypes.map((food, index) => (
                      <Grid item xs={12} key={index}>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            cursor: 'pointer',
                            padding: '8px',
                            borderRadius: '8px',
                            border: '1px solid #e0e0e0',
                            '&:hover': { bgcolor: '#f0f0f0' },
                          }}
                          onClick={() => handleFoodTypeClick(food)}
                        >
                          <Typography sx={{ marginRight: '5px' }}>{`#${index + 1}`}</Typography>
                          <img src={getFoodImagePath(food.type)} alt={food.type} style={{ width: '40px', height: '40px', borderRadius: '8px', marginRight: '10px' }} />
                          <Typography sx={{ fontWeight: 'bold' }}>{food.type}</Typography>
                          <Box display="flex" ml="auto">
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                moveFoodType(index, 'up');
                              }}
                              disabled={index === 0}
                              sx={{ fontWeight: 'bold', minWidth: '24px', padding: '2px', marginBottom: '-4px' }}
                            >
                              ↑
                            </Button>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                moveFoodType(index, 'down');
                              }}
                              disabled={index === selectedFoodTypes.length - 1}
                              sx={{ fontWeight: 'bold', minWidth: '24px', padding: '2px', marginBottom: '-4px', marginRight: '10px' }}
                            >
                              ↓
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Create Event Button */}
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateEvent}
          sx={{ padding: '12px 24px', fontSize: '16px', borderRadius: '8px', fontWeight: 'bold', textTransform: 'none' }}
        >
          Create Event
        </Button>
      </Box>
    </Box>
  );
};

export default Home;


