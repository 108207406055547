import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Drawer, List, ListItem, ListItemText, Button, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { handleLogout } from '../utils/logout';
import { auth, db } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';

interface BaseProps {
  children: React.ReactNode;
}

const Base: React.FC<BaseProps> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(null);
  const [userFirstName, setUserFirstName] = useState<string | null>(null);
  const [userLastName, setUserLastName] = useState<string | null>(null);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab') {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setDrawerOpen(false);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData && userData.pictureUrl) {
            setProfilePicUrl(userData.pictureUrl);
            setUserFirstName(userData.firstName);
            setUserLastName(userData.lastName);
          }
        }
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" bgcolor="#f5f5f5" fontFamily="'Roboto', sans-serif">
      <Box
        component="header"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        bgcolor="#1876D3"
        color="white"
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
      >
        <IconButton onClick={toggleDrawer(true)} color="inherit">
          <img src="/foodiate_logo.jpg" alt="Foodiate Logo" style={{ height: '40px', borderRadius: '8px' }} />
        </IconButton>

        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          sx={{ '& .MuiDrawer-paper': { width: '240px', padding: '16px', backgroundColor: '#f5f5f5' } }}
        >
          <List>
            {['Home', 'Events', 'Mood', 'Find', 'Contacts'].map((text) => (
              <ListItem button key={text} onClick={() => handleNavigation(`/${text.toLowerCase()}`)}>
                <ListItemText primary={text} sx={{ fontWeight: 'bold', color: '#1876D3' }} />
              </ListItem>
            ))}
          </List>
        </Drawer>

        {/* {profilePicUrl && (
          <Avatar
            src={profilePicUrl}
            alt="Profile Picture"
            sx={{ width: 40, height: 40, marginRight: 2 }}
          />
        )} */}

        <Box display="flex" alignItems="center">
          {userFirstName && (
            <Typography variant="body1" sx={{ marginRight: 1 }}>
              Hello, {userFirstName} {userLastName}
            </Typography>
          )}
          {profilePicUrl && (
            <Avatar
              src={profilePicUrl}
              alt="Profile Picture"
              sx={{ width: 40, height: 40, marginRight: 2 }}
            />
          )}
        </Box>

        <Button
          variant="outlined"
          color="inherit"
          onClick={() => handleLogout(navigate)}
          sx={{ borderColor: 'white', color: 'white', fontWeight: 'bold' }}
        >
          Logout
        </Button>
      </Box>

      <Box p={2} mt={2} flexGrow={1}>
        {children}
      </Box>

      <Box
        component="footer"
        p={2}
        mt="auto"
        bgcolor="#1876D3"
        color="white"
        textAlign="center"
        boxShadow="0px -4px 12px rgba(0, 0, 0, 0.1)"
      >
        <Typography variant="body1">Foodiate Inc.</Typography>
      </Box>
    </Box>
  );
};

export default Base;
