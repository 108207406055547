import React, { useState } from 'react';
import { Slider, Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const moods = ['Happy', 'Sad', 'Angry', 'Excited', 'Anxious', 'Calm', 'Frustrated', 'Content', 'Bored', 'Confused'];

const Mood = () => {
  const navigate = useNavigate();

  // Default each mood value to 50%
  const [moodValues, setMoodValues] = useState(Array(moods.length).fill(50));

  const handleSliderChange = (index: number, newValue: number) => {
    const newMoodValues = [...moodValues];
    newMoodValues[index] = newValue;
    setMoodValues(newMoodValues);
  };

  return (
    <Box sx={{ width: '80%', margin: '0 auto' }}>
      <Box display="flex" justifyContent="center" mb={2}>
        <h1>Mood</h1>
      </Box>

      <Grid container spacing={2}>
        
        {/* Mood Sliders */}
        {moods.map((mood, index) => (
          <Grid item xs={6} key={index}>
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <Typography variant="body1">{mood}</Typography>
              <Slider
                value={moodValues[index]}
                onChange={(e, value) => handleSliderChange(index, value as number)}
                step={1}
                min={0}
                max={100}
                sx={{ width: '90%' }} // Reduce slider width for a more compact look
              />
            </Box>
          </Grid>
        ))}

        {/* Group Circle */}
        <Grid item xs={12} textAlign="center">
          <Box
            sx={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              backgroundColor: 'blue', // Blue circle for Group
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              margin: '0 auto',
            }}
            onClick={() => navigate('/group')}
          >
            <Box sx={{ color: 'white', fontWeight: 'bold' }}>Group</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Mood;




