import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Base from './components/Base';
import Home from './components/Home';
// import Group from './components/Group';
import Events from './components/Events';
import Find from './components/Find';
// import Food from './components/Food';
import Contacts from './components/Contacts';
import Mood from './components/Mood';
import FDate from './components/FDate';
import Login from './components/Login';
import Registration from './components/Registration';
import ProtectedRoute from './components/ProtectedRoute';
import { EventProvider } from './components/EventContext';

const App = () => {
  return (
    <Router>
      <EventProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Base><Home /></Base>
              </ProtectedRoute>
            }
          />
          <Route
            path="/events"
            element={
              <ProtectedRoute>
                <Base><Events /></Base>
              </ProtectedRoute>
            }
          />
          <Route
            path="/find"
            element={
              <ProtectedRoute>
                <Base><Find /></Base>
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/food"
            element={
              <ProtectedRoute>
                <Base><Food /></Base>
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/contacts"
            element={
              <ProtectedRoute>
                <Base><Contacts /></Base>
              </ProtectedRoute>
            }
          />
          <Route
            path="/mood"
            element={
              <ProtectedRoute>
                <Base><Mood /></Base>
              </ProtectedRoute>
            }
          />
          <Route
            path="/fdate"
            element={
              <ProtectedRoute>
                <Base><FDate /></Base>
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </EventProvider>
    </Router>
  );
};

export default App;
