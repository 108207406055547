import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, TextField, IconButton, Avatar, FormControl, Select, MenuItem, SelectChangeEvent, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { auth } from '../services/firebase';
import { Contact } from './types';

const Contacts = () => {
  const user = auth.currentUser;
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchBy, setSearchBy] = useState<'firstName' | 'lastName'>('firstName');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [uniqueGroups, setUniqueGroups] = useState<string[]>([]);
  const [isSortedAscending, setIsSortedAscending] = useState<boolean>(true);
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [newContact, setNewContact] = useState<Contact | null>(null);
  const [addingNewGroup, setAddingNewGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);

      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            const userContacts = userData.contacts || [];
            setContacts(userContacts);
            setFilteredContacts(userContacts);
            const groups = Array.from(new Set(userContacts.flatMap((contact: Contact) => contact.contactGroup))) as string[];
            setUniqueGroups(groups);
          }
        })
        .catch(error => console.error('Error loading contacts:', error));
    }
  }, [user]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = contacts.filter(contact => 
      contact[searchBy].toLowerCase().includes(term) && 
      (selectedGroup === '' || contact.contactGroup.includes(selectedGroup))
    );
    setFilteredContacts(filtered);
  };

  const handleLetterClick = (letter: string) => {
    setSelectedLetter(letter);
    const filtered = contacts.filter(contact => 
      contact[searchBy].toLowerCase().startsWith(letter.toLowerCase()) &&
      (selectedGroup === '' || contact.contactGroup.includes(selectedGroup))
    );
    setFilteredContacts(filtered);
  };

  const handleGroupFilter = (event: SelectChangeEvent<string>) => {
    const group = event.target.value;
    setSelectedGroup(group);

    const filtered = contacts.filter(contact => 
      contact.contactGroup.includes(group) &&
      (searchTerm === '' || contact[searchBy].toLowerCase().includes(searchTerm))
    );
    setFilteredContacts(filtered);
  };

  const handleSearchByChange = (event: SelectChangeEvent<'firstName' | 'lastName'>) => {
    setSearchBy(event.target.value as 'firstName' | 'lastName');
  };

  const handleShowAll = () => {
    setSelectedLetter(null); // Clear the selected letter
    setFilteredContacts(contacts);
  };

  const handleSortToggle = () => {
    const sorted = [...filteredContacts].sort((a, b) => {
      const comparison = a[searchBy].localeCompare(b[searchBy]);
      return isSortedAscending ? comparison : -comparison;
    });
    setFilteredContacts(sorted);
    setIsSortedAscending(!isSortedAscending);
  };

  const handleAddContact = () => {
    setNewContact({
      userId: crypto.randomUUID(),
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      picUrl: '',
      contactGroup: [],
      address: { street: '', city: '', state: '', zip: '', country: '' },
      notes: '',
      socialMedia: { linkedIn: '', twitter: '' },
      birthday: '',
      online: false,
    });
    setOpenModal(true);
  };

  const handleDeleteContact = async (userId: string) => {
    const updatedContacts = contacts.filter(contact => contact.userId !== userId);
    await handleUpdateFirestore(updatedContacts);
  };

  const handleEditContact = (contact: Contact) => {
    setNewContact(contact);
    setOpenModal(true);
  };

  const handleSaveContact = async () => {
    if (newContact) {
        let contactGroup = [...newContact.contactGroup];
        
        if (addingNewGroup && newGroupName) {
            contactGroup.push(newGroupName);
        }

        const updatedContact = { ...newContact, contactGroup };

        let updatedContacts;

        if (updatedContact.userId && contacts.some(contact => contact.userId === updatedContact.userId)) {
            // Update existing contact
            updatedContacts = contacts.map(contact =>
                contact.userId === updatedContact.userId ? updatedContact : contact
            );
        } else {
            // Add new contact
            const newContactWithId = { ...updatedContact, userId: crypto.randomUUID() };
            updatedContacts = [...contacts, newContactWithId];
        }

        await handleUpdateFirestore(updatedContacts);
        
        setOpenModal(false);
        setNewContact(null); // Reset newContact to prevent incorrect re-use
        setAddingNewGroup(false); // Reset adding new group
        setNewGroupName(''); // Reset new group name
    }
  };

  const handleUpdateFirestore = async (updatedContacts: Contact[]) => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      try {
        await updateDoc(userDocRef, { contacts: updatedContacts });
        setContacts(updatedContacts);
        setFilteredContacts(updatedContacts);
      } catch (error) {
        console.error('Error updating contacts:', error);
      }
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h1">Contacts</Typography>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <IconButton onClick={() => setSearchTerm('')}>
          <SearchIcon />
        </IconButton>
        <Typography variant="body2" style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleShowAll}>
          Show All
        </Typography>
        <Typography variant="body2" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={handleSortToggle}>
          Sort {isSortedAscending ? 'A-Z' : 'Z-A'}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <FormControl variant="outlined" size="small">
          <Select value={searchBy} onChange={handleSearchByChange}>
            <MenuItem value="firstName">First Name</MenuItem>
            <MenuItem value="lastName">Last Name</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
        />

        <FormControl variant="outlined" size="small">
          <Select value={selectedGroup} onChange={handleGroupFilter} displayEmpty>
            <MenuItem value="">All Groups</MenuItem>
            {uniqueGroups.map(group => (
              <MenuItem key={group} value={group}>{group}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddContact}>
          Add Contact
        </Button>
      </Box>

      <Grid container>
        <Grid item xs={2}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(letter => (
              <Typography 
                key={letter} 
                onClick={() => handleLetterClick(letter)} 
                style={{ 
                  cursor: 'pointer', 
                  padding: '5px',
                  borderRadius: '50%',
                  backgroundColor: selectedLetter === letter ? 'green' : 'transparent',
                  color: selectedLetter === letter ? 'white' : 'black'
                }}
              >
                {letter}
              </Typography>
            ))}
          </Box>
        </Grid>
        
        <Grid item xs={10}>
          <Box>
            {filteredContacts.map(contact => (
              <Box key={contact.userId} display="flex" alignItems="center" mb={2}>
                <Avatar src={contact.picUrl} />
                <Box ml={2}>
                  <Typography variant="body1">{contact.firstName} {contact.lastName}</Typography>
                  <Typography variant="body2">{contact.contactGroup.join(', ')}</Typography>
                </Box>
                <Box ml="auto">
                  <IconButton onClick={() => handleEditContact(contact)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteContact(contact.userId)}>
                    <DeleteIcon />
                  </IconButton>
                  {contact.online && <Typography variant="body2" color="green">Online</Typography>}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>{newContact?.userId ? 'Edit Contact' : 'Add Contact'}</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            value={newContact?.firstName || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact!, firstName: e.target.value })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Last Name"
            value={newContact?.lastName || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact!, lastName: e.target.value })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Email"
            value={newContact?.email || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact!, email: e.target.value })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Phone"
            value={newContact?.phone || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact!, phone: e.target.value })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Street"
            value={newContact?.address.street || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, address: { ...newContact.address, street: e.target.value } })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="City"
            value={newContact?.address.city || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, address: { ...newContact.address, city: e.target.value } })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="State"
            value={newContact?.address.state || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, address: { ...newContact.address, state: e.target.value } })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Zip"
            value={newContact?.address.zip || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, address: { ...newContact.address, zip: e.target.value } })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Country"
            value={newContact?.address.country || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, address: { ...newContact.address, country: e.target.value } })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="LinkedIn"
            value={newContact?.socialMedia.linkedIn || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, socialMedia: { ...newContact.socialMedia, linkedIn: e.target.value } })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Twitter"
            value={newContact?.socialMedia.twitter || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, socialMedia: { ...newContact.socialMedia, twitter: e.target.value } })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Notes"
            value={newContact?.notes || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, notes: e.target.value })}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Birthday"
            value={newContact?.birthday || ''}
            onChange={(e) => newContact && setNewContact({ ...newContact, birthday: e.target.value })}
            margin="dense"
            fullWidth
          />

          <FormControl fullWidth margin="dense">
            <Select
              value={newContact?.contactGroup || []}
              onChange={(e) => {
                const value = e.target.value as string[];
                if (newContact) {
                  setNewContact({ ...newContact, contactGroup: value });
                }
              }}
              multiple
              renderValue={(selected) => selected.join(', ')}
            >
              {uniqueGroups.map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
              <MenuItem key="newGroup" value="newGroup" onClick={() => setAddingNewGroup(true)}>
                <em>Add New Group</em>
              </MenuItem>
            </Select>
          </FormControl>

          {addingNewGroup && (
            <TextField
              label="New Group Name"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              margin="dense"
              fullWidth
            />
          )}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="secondary">Cancel</Button>
          <Button onClick={handleSaveContact} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Contacts;
